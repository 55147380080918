.row-howitworks {
    max-width: 700px;
    margin: auto;
}

.p-howitworks {
    text-align: justify;
}

.p-howitworks-indent {
    text-indent: 30px;
    text-align: justify;
}