.security-img {
  height: 80px;
  width: 80px;
  margin-right: 5px;
  margin-top: 10px;
}

.security-p {
  textAlign: 'left';
  fontSize: '20px';
}

.row-security {
    max-width: 800px;
    margin: auto;
    margin-bottom: 40px;
}