.header {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 3;
  transition: all 0.5s ease;
  padding: 10px 0;

  .logo {
    padding: 6px 0 0 0;

    h1 {
      color: #505050;
      font-family: "Philosopher", sans-serif;
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 1px;
      margin: 0;

      a {
        color: #555;
        line-height: 1;
        display: inline-block;
        text-decoration: none;

        span {
          color: #71c55d;
        }
      }
    }

    img {
      padding: 0;
      margin: 0;
    }
  }

  &.header-scrolled {
    background: #fff;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
  }

  .nav-menu {
    margin-left: auto;
    margin-right: 5px;

    .nav-link {
      padding: 8px 8px;
      text-decoration: none;
      display: inline-block;
      color: #555;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px;
      outline: none;
      letter-spacing: 0;
      transition: 0.3s;

      &:hover, &.active  {
        color: #71c55d;
      }
    }
  }

  .user-greetings {
    padding: 8px 8px;
    font-weight: 400;
    font-size: 14px;
    color: #71c55d;
  }
}

.center-text {
  text-align: center;
}