.aboutus-h3 {
    text-align: left;
}

.aboutus-p {
    margin-left: 10px;
}

a {
    color: #505050;
    text-decoration: underline;
}